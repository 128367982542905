.Input {
    box-shadow: none !important;
    outline: none !important;

    height: 40px;
    min-height: 40px;
    max-height: 40px;
    border-radius: 10px;

    border: 1px solid #efefef;

    padding: 6px 12px;

    width: 100%;
}