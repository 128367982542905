.login_page_con {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
  
  .login_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .login_header {
    display: flex;
    align-items: center;
    gap: 258px;
    margin-bottom: 30px;
  }
  
  .login_header img {
    width: 200px;
    height: 40px;
  }
  
  .login_header a {
    text-decoration: none;
    color: #007bff;
  }
  
  
  
  .login_main h2 {
    margin-bottom: 20px;
    font-family: 'Segoe UI', Verdana, sans-serif;
    font-size: 28px;
    line-height: 38px;
  }
  
  .login_main form {
    display: flex;
    flex-direction: column;
  }
  
  .login_main button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);
  }
  
  .image_container {
    display: flex;
    background-color: #5584CE;
    flex: 1;
    justify-content: center;
    align-items: center;
  }