.Checkbox {
    display: none;
    max-width: fit-content;
}

.Checkbox + label {
    display: inline-block;
    width: 30px;
    height: 17px;
    background-color: #ccc;
    border-radius: 17px;
    position: relative;
    cursor: pointer;
}

.Checkbox + label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    transition: transform 0.3s;
}

.Checkbox:checked + label {
    background-color: #007bff;
}

.Checkbox:checked + label:after {
    transform: translateX(13px);
}