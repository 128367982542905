.Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    outline: none;
    border: none;
    padding: 6px 12px;
    border-radius: 10px;
    transition: 150ms all;
}

.Button__type__primary {
    background: #3082d8;
    color: #ffffff;
}

.Button__type__primary:hover {
    background: rgba(0, 123, 255, 0.8);
    color: #ffffff;
}

.Button__type__destructive {
    background: #ed3355;
    color: #ffffff;
}

.Button__type__destructive:hover {
    background: rgba(234, 74, 103, 0.8);
    color: #ffffff;
}

.Button__type__secondary {
    background: #efefef;
    color: #000;
}

.Button__type__secondary:hover {
    background: #fff;
    color: #000;
}

.Button__size__small {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
}

.Button__size__medium {
    width: 100%;
    padding: 6px 12px;
    border-radius: 10px;
    min-width: 150px;
}

.Button__size__large {
    width: 100%;
    padding: 12px 24px;
    border-radius: 10px;
    min-width: 150px;
}

.Button__fixed {
    width: fit-content;
}

