.Analytics__Row {
    display: flex;
    justify-content: space-between;
}

.Analytics__Col {
    display: flex;
    flex-direction: column;
    width: 220px;
    min-width: 220px;
}

.Analytics__Col--center {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 24px;
    margin-left: 24px;
}

.Analytics__Period {
    background: #efefef;
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 16px;
    width: fit-content;
}

.Analytics__PeriodHeading {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.Analytics__PeriodBody {
    display: flex;
    width: 100%;
    gap: 8px;
}

.Analytics__PeriodBody span {
    font-size: 20px;
    font-weight: 500;
}

.Analytics__PeriodBody input {
    border: none;
    outline: none;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    background: #d3d1d1;
    border-radius: 10px;
}

.Analytics__Card {
    background: #efefef;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 10px;
    width: 100%;
    height: fit-content;
}

.Analytics__CardHeader {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.Analytics__Chart__Gauge {
    width: 100%;
    height: auto;
    margin: 20px auto;
}

.Analytics__Chart__Background {
    position: relative;
    height: 100px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 150px 150px 0 0;
    overflow: hidden;
    text-align: center;
}

.Analytics__Chart__Mask {
    position: absolute;
    top: 20px;
    right: 20px;
    left: 20px;
    height: 80px;
    background-color: #efefef;
    border-radius: 150px 150px 0 0
}

.Analytics__Chart__Percentage {
    position: absolute;
    top: 100px;
    left: -200%;
    width: 400%;
    height: 400%;
    margin-left: 100px;
    background-color: #40c740;
}

.Analytics__Chart__Percentage {
    transform: rotate(var(--angle));
    transform-origin: top center;
}

.Analytics__Chart__Min {
    float: left;
}

.Analytics__Chart__Max {
    float: right;
}

.Analytics__Chart__Value {
    position: absolute;
    top: 70%;
    left: 0;
    width: 100%;
    font-size: 16px;
    font-weight: 500
}

.Analytics__Chart__PercentValue {
    position: absolute;
    top: 35%;
    left: 0;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    color: #40c740;
}

.Analytics__Balances {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.Analytics__Balances span {
    color: #40c740;
    font-weight: 500;
}

.Analytics__Deposit {
    color: #40c740;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.Analytics__Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    outline: none;
    border: none;
    padding: 6px 12px;
    background: #007bff;
    border-radius: 10px;
    color: #ffffff;
    min-width: 150px;
}