.distributed {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: auto; 
}

.filters-panel {
    width: 300px;
    background-color: #f8f9fa;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
    display: none;
    transition: transform 0.3s ease-in-out;
    max-height: calc(100vh - 87px);
    overflow-y: auto;
}

/* .container-fluid{
    overflow-x: auto;
} */
.filters-panel.visible {
    display: block;
}

.show-filters-btn {
    background-color:white;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 7px 0 0;
}

.show-filters-btn:hover {
    background-color: #d9d9da;
}

.btn-closeleft {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    background: transparent;
    border: none;
}
.filter-icon {
    width: 30px;  
    height: 30px;
    object-fit: contain;
  }

.filter-icon-close {
    width: 30px;  
    height: 30px;
    object-fit: contain;
  }
.filters-panel .d-flex input[type="date"] {
    width: calc(50% - 36px);
    box-sizing: border-box; 
  }
.form-group{
    margin-top: 17px;
    
}
.apply{
    margin-top: 17px;
}

/* .table-responsive{
    /* height: '75vh';
    overflow-y: 'scroll'; */
    /* overflow-x: auto;
    width:68vw; */
/* } */ 