.block-tree {
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    width: 280px;
    position: relative;
    max-width: 400px;
}

.main-group-wrapper {
    margin-top: -20px; /* Компенсация верхней линии */
}

.organization-tree {
    padding-top: 20px; /* Чтобы контент не сдвигался вверх */
}

/* Скрываем верхнюю линию главной группы */
.main-group-wrapper > div > ul::before {
    display: none !important;
}

.ul.css-to7de5 > li:first-child::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 2px;
    height: 20px;
    background: #d1d5db;
    transform: translateX(-50%);
    z-index: -1;
}

.block-tree-header {
    font-size: 1rem;
    font-weight: 600;
    color: #2c3e50;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    border-radius: 7px 7px 0 0;
    padding: 12px 15px;
    text-align: center;
}

.block-tree-body {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.tree-container::before {
    display: none;
}

.tree-container {
    width: 100%;
    height: calc(100vh - 100px);
    overflow: auto;
    padding: 20px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.tree-wrapper {
    display: flex;
    justify-content: center;
    min-width: max-content;
    max-width: min-content;
    padding: 20px 10px;
}

.tree-node {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    max-width: 300px;
    margin: 0 auto;
}

.tree-children {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 40px;
    padding-top: 20px;
    width: 100%;
}

.tree-node:not(.main-group):not(:only-child)::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 2px;
    height: 20px;
    background: #d1d5db;
    transform: translateX(-50%);
    z-index: -1;
}

.tree-node:only-child::before {
    content: '';
    position: absolute;
    top: -60px;
    left: 50%;
    width: 2px;
    height: 60px;
    background: #d1d5db;
    transform: translateX(-50%);
    z-index: -1;
}

.tree-children:has(> .tree-node:only-child)::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 60px;
    background: #d1d5db;
    transform: translateX(-50%);
    z-index: -1;
}

.tree-node:has(> .tree-children):not(:has(> .tree-children:empty)) > .card::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 2px;
    height: 20px;
    background: #d1d5db;
    transform: translateX(-50%);
    z-index: -1;
}

.tree-children::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #d1d5db;
    z-index: -1;
}

.tree-wrapper > .tree-node:first-child::before,
h2 + .tree-container .tree-node:first-child::before {
    display: none;
}

.tree-node.main-group {
    padding-top: 0;
}

h2 + .tree-container .tree-node.main-group::before,
.tree-wrapper > .tree-node.main-group::before {
    content: none;
}

.tree-node:not(:first-child)::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 2px;
    height: 20px;
    background: #d1d5db;
    transform: translateX(-50%);
    z-index: -1;
}

.main-group-line::before {
    display: none;
}

.tree-node.main-group > .card {
    border: 2px solid #4a5568;
    margin-bottom: 20px;
}

.tree-node.main-group:has(> .tree-children):not(:has(> .tree-children:empty)) > .card::after {
    height: 20px;
    bottom: -20px;
}

.tree-node.main-group::before {
    display: none;
}

.tree-node:has(> .tree-children:empty)::after {
    display: none;
}

.tree-node:not(:has(> .tree-children))::after {
    display: none !important;
}

.tree-node.main-group::after {
    display: none !important;
}

.tree-node p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #4a5568;
}

.card-body .bi-person-circle,
.card-body .bi-people {
    font-size: 1.2rem;
    color: #6c757d;
    margin-right: 0.5rem;
}

.tree-container::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.tree-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

.tree-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

.modal-finmodel {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

.modal-dialog-finmodel {
    max-width: 500px;
    width: 90%;
    margin: 1.75rem auto;
}
.fixed-size-modal {
    width: 800px;
    max-width: 500px;
}

.fixed-size-modal .modal-content {
    min-height: 600px;
}

.fixed-size-modal .modal-body {
    height: 450px;
    overflow-y: auto;
}

/* Стили для таблицы внутри модального окна */
.fixed-size-modal .table-responsive {
    height: 350px;
    max-height: 450px;
    overflow-y: auto;
}

/* Фиксированный header для таблицы */
.fixed-size-modal .table thead th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
}