.Payments__wrapper {
    background: #fff;
    width: 100%;
    border-radius: 16px;
    height: calc(100vh - 87px - 1rem - 57px - 36px - 150px);
    max-height: calc(100vh - 87px - 1rem - 57px - 36px - 150px);
}

.Payments__table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    min-width: 800px;
    width: 100%;
}

.Payments__table tr {
    text-align: left;
    border-bottom: 1px solid #fff;
}

.Payments__table th {
    position: sticky !important;
    top: 0;
    z-index: 1;
    background: #efefef;
}

.Payments__table td, .Payments__table th {
    padding: 20px;
    width: 200px;
}

.Payments__table thead {
    background: #efefef;
}

.Payments__table thead th:first-child {
    max-width: 70px;
    width: 70px;
}

.Payments__table tbody tr:nth-child(odd){
    background: #fff;
}
.Payments__table tbody tr:nth-child(even){
    background: #efefef;
}
