.searchable-select {
    position: relative;
    
}

.select-dropdown {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

}


.select-dropdown .list-group-item {
    cursor: pointer;    

}

.select-dropdown .list-group-item:hover {
    background-color: #f8f9fa;
}

.red-x {
    margin-left: 10px;
    color: red;
    font-weight: bold;
}

.disabled {
    pointer-events: none;
    opacity: 1;
    color: #6c757d;
    background-color: #e9ecef;
  }
