.SideBar {
    display: flex;
    flex-direction: column;
    max-width: 14vw;
    background: #fff;
}

.SideBar__Item {
    display: flex;
    min-height: 50px;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .2s;
    margin-top: 10px;
}

.SideBar__Item:hover {
    background: #efefef;
}

.SideBar__current {
    background: #efefef;
}

.SideBar__icon {
    background: #efefef;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.SideBar__current .SideBar__icon {
    background: #fff
}

.SideBar__title {
    font-size: 14px;
    font-weight: 500;
}

.SideBar__SubItem {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding-left: 55px;
    margin-bottom: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .2s;
}

.SideBar__SubItem:hover {
    background: #efefef;
}