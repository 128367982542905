.Budgets__wrapper {
    width: 100%;
    overflow-x: auto;
    background: #fff;
    border-radius: 16px;
}

.Budgets__table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    min-width: 1000px;
}

.Budgets__table th,
.Budgets__table td {
    border: 1px solid #ccc;
    padding: 8px 12px;
    word-wrap: break-word;
    vertical-align: top;
    text-align: left;
    font-weight: 400;
    box-sizing: border-box;
}

.Budgets__table thead {
    background: #efefef;
}

.Budgets__table thead th {
    position: sticky;
    top: 0;
    z-index: 10;
    font-weight: 400;
}

.Budgets__table tbody tr:nth-child(odd) {
    background: #fff;
}

.Budgets__table tbody tr:nth-child(even) {
    background: #f9f9f9;
}

.Budgets__table th:first-child,
.Budgets__table td:first-child {
    width: 220px;
    min-width: 220px;
}

.caption-table-container {
    overflow-x: auto;
    max-width: 100%
}

.sticky-table {
    border-collapse: collapse;
    width: 100%;
}

.sticky-table th,
.sticky-table td {
    white-space: nowrap;
}

.sticky-col {
    position: sticky;
    background: #fff;
    z-index: 10;
    border-bottom: 1px solid #fff;
}

.sticky-col-1 {
    left: 0;
}
.sticky-col-2 {
    left: 31px;
}
.sticky-col-3 {
    left: 231px;
}
.sticky-col-4 {
    left: 430px
}

.sticky-table thead th {
    position: sticky;
    top: 0;
    z-index: 20;
}
