.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    z-index: 1000;
}

.logo {
    height: 30px;
}

.avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.profile-dropdown {
    width: 250px;
    right: 0;
}

.no-scroll {
    overflow: hidden;
}

.Search__input {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    outline: none;
    border-radius: 10px;
}

.Header__Profile {
    display: flex;
    align-items: center;
    gap: 6px;
}

.Header__Profile__image {
    max-width: 48px;
}

.Header__Profile__name {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
}

.Header__Profile__email {
    font-size: 14px;
    line-height: 18px;
}

.Header__List {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.Header__List__item {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #efefef;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.Header__List__item i {
    font-size: 16px;
    color: #fff;
}

.Header__List__icon {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: #3082d8;
    border-radius: 6px;
}

.destructive .Header__List__icon {
    background: #dc3652;
}