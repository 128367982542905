
.container_reg {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
.registration_card {
flex: 3;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
}

.registration_header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 258px;
    margin-bottom: 30px;
}

.registration_header img {
    width: 200px;
    height: 40px;
}


.registration_form {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.registration_form .submit-button {
width: 350px;
padding: 10px 20px;
color: #fff;
border: none;
border-radius: 10px;
cursor: pointer;
background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);
}

.form_input {
display: flex;
flex-direction: row;
}

.form_column {
flex: 1;
margin-right: 20px;
}

.image_card {
display: flex;
background-color: #5584CE;
flex: 1;
justify-content: center;
align-items: center;
}
.password-toggle {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);
  }