.navigation_main {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
  
  .background1, .background2 {
    position: absolute;
    top: 0;
    width: 50%; /* Половина ширины контейнера */
    height: 100%;
  }
  
  .background1 {
    background-color: white;
    display: flex;
    justify-content: center;
    z-index: 0;
    left: 0;
  }
  
  .background2 {
    background-color: #5584CE;
    justify-content: center;
    display: flex;
    z-index: 0;
    right: 0;
  }
  
  .nav_img {
    width: 587px;
    height: 154px;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.butt {
  height: 40px;
  margin-top: 200px;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);
  text-decoration: none;
}