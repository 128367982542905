.Heading {

}

.Heading__1 {
    font-size: 50px;
    font-weight: 600;
}

.Heading__2 {
    font-size: 30px;
    font-weight: 600;
}

.Heading__3 {
    font-size: 28px;
    font-weight: 600;
}

.Heading__4 {
    font-size: 24px;
    font-weight: 500;
}

.Heading__5 {
    font-size: 20px;
    font-weight: 500;
}

.Heading__6 {
    font-size: 16px;
    font-weight: 500;
}