.sliding-panel {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 90%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1050;
}

.sliding-panel.open {
    transform: translateX(0);
}

.sliding-panel-content {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}


.sliding-panel-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
    z-index: 1049;
}

.sliding-panel-backdrop.open {
    opacity: 1;
    visibility: visible;
}

.no-scroll {
    overflow: hidden;
}


.caption-table-container {
    height: 70vh;
    max-height: 800px; /* Adjust height as needed */
    max-width: 100%; /* Adjust width to fit your layout */
    overflow: auto;
    /* border: 1px solid #ddd; Optional: adds a border around the scrollable area */
}

.caption-table-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.caption-table-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.caption-table-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.cell-width {
    min-width: 100px;
}