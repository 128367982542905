@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500');

body {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.container-fluid {
  padding: 0;
}

.row {
  margin: 0;
}

.col-auto {
  padding: 0;
  width: 12vw;
}


.management {
  padding: 20px;
}


.table th,
.table td {
  vertical-align: middle;
  text-align: center;
}


.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 85vw;
}

.table-container table{
  white-space: nowrap;  
}


@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}

.integration-card {
  max-width: 600px; /* Установите желаемую ширину */
  background-color: #e0e0e0;
}
.btn-orange {
  background-color: orange;
  color: black;
}

.integration-card {
  background-color: #e0e0e0; /* Цвет фона карточки */
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: orange;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
