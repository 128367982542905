

.deciphercontent {
    display: block;
    margin-block: 15px;
}

.deciphers {
    display: block;
    width: 55vw;
}

.decipher.row {
    background-color: #D9D9D97D;
    border-radius: 10px;
    margin: 10px;
}

.guidecountparties{
    text-align: center;
}